<template>
    <div>
        <b-card>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col md="1">
              <div class="d-flex justify-content-center align-items-center">
                <b-img fluid :src="category.cover"></b-img>
              </div>
            </b-col>
            <b-col class="my-auto" md="9">
              <h4>
                <span style="color: #7367f0">id :</span>
                {{ category.id }}
              </h4>
              <h4>
                <span style="color: #7367f0">name :</span>
                {{ category.name }}
              </h4>
              <h4>
                <span style="color: #7367f0">Code : </span>
                {{ category.code }}
              </h4>
              <br />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

        <b-card-code title="Manage Category Attributes" :before-change="validationForm">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>


                        <b-col sm="12">
                            <hr>
                            <p class="h4">Attributes </p>
                            <!-- Row Loop -->
                            <b-row v-for="(item, index) in data.attributes" :id="'attribute-' + index + 1"
                                :key="'attribute-' + index + 1" ref="row">

                                <!-- locale -->
                                <b-col md="4">
                                    <validation-provider #default="{ errors }" name="Name" rules="required">
                                        <b-form-group label="Name" label-for="Name">
                                            <v-select v-model="selectedAttributes[index]" :disabled="!item.is_new"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                                                value="id" :state="errors.length > 0 ? false : null"
                                                :options="attributesOptions.map(attribute => ({ id: attribute.id, text: attribute.name, is_variant: attribute.is_variant }))"
                                                @input="updateSortValidation(index)" />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>

                                <b-col md="4" v-if="selectedAttributes[index] && selectedAttributes[index].is_variant">
                                    <validation-provider #default="{ errors }" name="sort">
                                        <b-form-group label="sort" label-for="sort">
                                            <b-form-input type="number" v-model="item.sort" 
                                                @input="updateSortValidation(index)"
                                                :state="sortValidationErrors[index] ? false : null" />
                                            <small class="text-danger">{{ sortValidationErrors[index] }}</small>
                                        </b-form-group>
                                    </validation-provider>

                                </b-col>

                                <b-col md="3">
                                    <b-form-group label="Add Name In Product Name ?" label-for="is_include_name">
                                        <validation-provider #default="{ errors }" name="is_include_name">
                                            <b-form-checkbox switch v-model="item.is_include_name" class="mt-1">
                                                <p v-if="item.is_include_name">
                                                    Add Attribute Name In Product Name
                                                </p>
                                                <p v-else>Remove Attribute Name From Product Name</p>
                                            </b-form-checkbox>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>

                                </b-col>


                                <!-- Remove Button -->
                                <b-col md="4" class="mb-50">
                                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" v-if="index >= 1 && item.is_new"
                                        variant="outline-danger" class="mt-0 mt-md-2" @click="removeAttribute(index)">
                                        <feather-icon icon="XIcon" class="mr-25" />
                                        <span>Remove Attribute</span>
                                    </b-button>
                                </b-col>
                                <b-col cols="12">
                                    <hr />
                                </b-col>
                            </b-row>

                            <!-- add new button -->
                            <b-col cols="12" v-if="attributesOptions.length > 0">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                                    @click="addAttribute">
                                    <feather-icon icon="PlusIcon" class="mr-25" />
                                    <span>Add Attribute</span>
                                </b-button>
                                <hr />
                            </b-col>


                        </b-col>

                        <!-- submit button -->
                        <b-col>
                            <b-button size="lg" variant="primary" type="submit" @click.prevent="validationForm">
                                Submit
                            </b-button>
                        </b-col>


                    </b-row>
                </b-form>
            </validation-observer>
        </b-card-code>
        <!-- error handelr -->
        <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
            variant="danger">
            <h4 class="alert-heading">
                Alert
            </h4>
            <div class="alert-body">
                <ul v-for="(values, index) in errors_back" :key="index">
                    <li v-for="(value, valIndex) in values" :key="valIndex">{{ value }}</li>
                </ul>
            </div>
        </b-alert>
    </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
export default {
    components: {
        VueEditor,
        BCardCode,
    },
    mixins: [heightTransition],
    data() {
        return {
            sortValidationErrors: [],
            attributesOptions: [],
            ProfilePictureBase64: [],
            ProfilePictureBase642: [],
            activeSelect: null,
            selectedAttributes: [],
            category: {},


            data: {
                attributes: [],

            },


            errors_back: '',
            showDismissibleAlert: false,
            required,
        }
    },
    watch: {
        selectedAttributes: {
            deep: true,
            handler(newSelectedAttributes, oldSelectedAttributes) {
                // console.log(this.selectedAttributes[0])
                // console.log(this.data.attributes)
                // Loop through the new selectedAttributes array
                newSelectedAttributes.forEach((selectedAttribute, index) => {
                    // Update the corresponding ID in data.attributes[index].id
                    this.$set(this.data.attributes, index, { ...this.data.attributes[index], id: selectedAttribute.id });
                });
                // console.log(this.data.attributes)
            },
        },
    },
    async created() {
        await this.fetchCreateData()
    },
    methods: {

        updateSortValidation(index) {
            if (this.selectedAttributes[index] && this.data.attributes[index].sort != null) {
                const otherSorts = this.data.attributes.filter((cat, idx) => idx !== index).map(cat => Number(cat.sort));
                const selectedSort = Number(this.data.attributes[index].sort);


                if (otherSorts.includes(selectedSort)) {
                    this.$set(this.sortValidationErrors, index, 'Sort value already exists in the selected attribute');

                } else {
                    this.$set(this.sortValidationErrors, index, null); // Clear the error message if no validation error
                }

            }


        },
        addAttribute() {
            this.data.attributes.push(
                {
                    id: null,
                    sort: null,
                    is_include_name: false,
                    is_new: true,
                }
            )

        },

        removeAttribute(index) {
            if (this.data.attributes.length <= 1) {
                this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
                    title: 'Alert',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Accept',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
            } else {
                this.data.attributes.splice(index, 1)
            }
        },
        fetchCreateData() {
            axios
                .get('categories/' + this.$route.params.id + '/edit')
                .then((result) => {

                    this.attributesOptions = result.data.data.attributes
                    this.category = result.data.data.category
                    this.data.attributes = this.category.attributes.map(attr => ({
                        id: attr.id,
                        sort: attr.pivot.sort,
                        is_include_name: attr.pivot.is_include_name,
                        is_new: false,
                    }));

                    this.selectedAttributes = this.category.attributes.map(attr => ({
                        id: attr.id,
                        is_variant: attr.is_variant,
                        text: attr.name,
                    }));

                })
                .catch((err) => {
                    this.$swal({
                        position: 'center',
                        icon: 'error',
                        title: 'Error!',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data,
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error',
                        })
                        this.showDismissibleAlert = true
                    }
                })

        },
        // submit Category API
        validationForm() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                  

                    axios
                        .post('categories/' + this.$route.params.id + '/attributes/async', this.data)
                        .then((result) => {
                            this.$swal({
                                position: 'center',
                                icon: 'success',
                                title: 'Your work has been saved',
                                showConfirmButton: false,
                                timer: 1500,
                            })
                            this.$router.push({
                                path: '/Show-Category/' + this.$route.params.id ,
                            })
                        })
                        .catch((err) => {
                            this.$swal({
                                position: 'center',
                                icon: 'error',
                                title: 'Error!',
                                showConfirmButton: false,
                                timer: 1500,
                            })
                            this.errors_back.length = 0
                            if (err.response.data.data != null) {
                                if (this.isString(err.response.data.data)) {
                                    this.errors_back.push({
                                        error: err.response.data.data,
                                    })
                                } else {
                                    this.errors_back = err.response.data.data
                                }
                                this.showDismissibleAlert = true
                            } else {
                                this.errors_back = []
                                this.errors_back.push({
                                    error: 'internal server error',
                                })
                                this.showDismissibleAlert = true
                            }
                        })
                }
            })
        },

        // REPETED FORM
        repeateAgain() {
            this.data.translations.push({
                //  id: this.nextTodoId += this.nextTodoId,
            })
            this.tranLocaleSelect.push({})
        },
        // remove function to delete single translation from category
        removeItem(index) {
            if (this.data.translations.length <= 1) {
                this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
                    title: 'Alert',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Accept',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
            } else {
                this.data.translations.splice(index, 1)
                this.tranLocaleSelect.splice(index, 1)
            }
        },
        isString(value) {
            return typeof value === 'string' || value instanceof String
        },
        // basic
    },
}
</script>

<style lang="scss"></style>


<style lang="scss" scoped></style>